import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <header className="major">
          <h2>Estimulació musical en família</h2>
        </header>
        <p>
          Les <strong>sessions d’estimulació musical en família</strong> estan dividides majoritàriament en <strong>dues franges d’edat</strong> per adaptar-se millor a les necessitats de cada etapa evolutiva:
        </p>

        <ul>
          <li>Dels 4 als 12 mesos</li>
          <li>D'1 a 3 anys</li>
        </ul>

        <p>
          Durant els primers anys de vida, la principal forma de comunicació de l’infant és a través del so. Per aquest motiu, l’estimulació musical és fonamental per afavorir el seu desenvolupament, ajudant-lo a descobrir-se a ell mateix, el seu entorn i la seva pròpia veu. A més, permet als infants explorar la relació amb els pares, el seu cos, l’escolta, la imitació, la creació i el coneixement dels instruments.
        </p>
        <p>
          Durant les sessions es generarà un espai per compartir i enfortir el vincle entre els pares/mares i els fills/es a partir de vivències musicals. Es durà a terme una <strong>estimulació sensorial global</strong> de l’infant a partir de cançons, sons, materials diversos, instruments, audicions, moviment, danses, jocs de falda, improvisació i relaxació.
        </p>
        <p>
          A més, oferim una <strong>opció de música en família amb un apropament a la parla anglesa</strong>. En aquesta modalitat, durant una part de la sessió, la <strong>Kate</strong>, <strong>the Little Bunny</strong>, una simpàtica conilleta anglesa, ens acompanya per ensenyar cançons en anglès, oferint als infants una <strong>primera immersió en la llengua</strong> de manera natural i divertida.
        </p>
        <p>
          Les sessions es poden fer de manera <strong>setmanal</strong>, <strong>quinzenal</strong> o com <strong>tallers puntuals</strong>, per adaptar-se a les necessitats de cada família i proporcionar una experiència musical enriquidora.
        </p>
      </section>
      <section id="two">
        <h2>Objectius de les sessions</h2>
        <ul>
          <li>Gaudir en família de les propostes presentades</li>
          <li>Fomentar l’atenció, la concentració i la memòria</li>
          <li>Explorar els diferents instruments i materials que es presenten i el so que generen</li>
          <li>Potenciar la coordinació oculo-manual</li>
          <li>Fomentar la capacitat motora grossa i fina</li>
          <li>Estimular la psicomotricitat de l’infant</li>
          <li>Sincronitzar moviments a una font externa (peça de música)</li>
          <li>Enfortir l’autoestima, la seguretat en si mateix i l’autoconfiança</li>
          <li>Enfortir el vincle família-infant</li>
          <li>Fomentar les habilitats conductuals, socials i de comunicació dels infants</li>
          <li>Promoure l'aprenentatge d'idiomes: Fomentar l'aprenentatge i la comprensió d'anglès a través de cançons, jocs i activitats musicals, afavorint la comprensió auditiva i l'exposició a un segon idioma.</li>
        </ul>

      </section>
      <section id="three">
        <h2>Propers tallers</h2>
        <p>
          Per consultar els tallers d'estimulació musical que es realitzaran properament,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            clica aquí!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
